import React from 'react';
import { Control, FieldErrors } from 'react-hook-form';
import get from 'lodash/get';
import noop from 'lodash/noop';
import omit from 'lodash/omit';
import {
  USCISANumberFieldName,
  countryOfIssuanceFieldName,
  documentExpirationDateFieldName,
  firstNameFieldName,
  fieldDefinitions,
  foreignPassportNumberFieldName,
  i551NumberFieldName,
  i766NumberFieldName,
  i94NumberFieldName,
  lastNameFieldName,
  usPassportNumberFieldName,
  dobFieldName,
} from '../../constants';
import { getFieldError, RegisterFn, registerNoop, SetValueFn } from '../../../../../utils/forms';
import CountrySelect from '../../../../CountrySelect/CountrySelect';
import {
  BaseFormFieldData,
  DateFormFieldData,
  FieldType,
  FormFieldData,
} from '../../../../Form/FormFields/FormFieldsModernized';
import {
  SelfCheckCaseReverificationFieldName,
  SelfCheckCaseReverificationRequest, // TODO investigate if this lost functionality
} from '../../../../../services/SelfCheckApi';

export interface ReverifySectionFieldData {
  name: SelfCheckCaseReverificationFieldName;
  value?: BaseFormFieldData['value'];
  register?: RegisterFn;
}

export interface ReactHookFormProps<_T = any> {
  control?: Control;
  errors?: FieldErrors;
  register: RegisterFn;
  setValue: SetValueFn<SelfCheckCaseReverificationRequest>;
  watch?: (name: string) => any;
}

export const getFieldTypeForFormFieldName = (name: ReverifySectionFieldData['name']): FieldType | undefined => {
  switch (name) {
    case firstNameFieldName:
    case lastNameFieldName:
    case USCISANumberFieldName:
    case i551NumberFieldName:
    case i766NumberFieldName:
    case i94NumberFieldName:
    case usPassportNumberFieldName:
    case foreignPassportNumberFieldName:
      return FieldType.TEXT;
    case countryOfIssuanceFieldName:
      return FieldType.CUSTOM;
    case documentExpirationDateFieldName:
      return FieldType.DATE;
    case dobFieldName:
      return FieldType.READ_ONLY;
    default:
      return undefined;
  }
};

const getFormFieldDataForReverifySectionFieldData = (
  fieldData: ReverifySectionFieldData,
  { control, errors, register, setValue, watch }: ReactHookFormProps,
): FormFieldData | undefined => {
  const { name, value: defaultValue } = fieldData;
  const fieldType = getFieldTypeForFormFieldName(name);

  if (!fieldType) {
    return undefined;
  }
  const label = get(fieldDefinitions, [name, 'label']);
  const registeredField = register(name, {
    required: true,
    ...get(fieldDefinitions, [name, 'validation'], {}),
  });

  const currentValue = watch?.(name) ?? defaultValue ?? '';

  const formFieldData = {
    fieldType,
    id: name,
    label,
    required: true,
    // defaultValue,
    errorMessage: getFieldError(get(errors, name), get(fieldDefinitions, [name, 'errorMessages'])),
    value: currentValue,
    ...registeredField,
  };

  if (fieldType === FieldType.DATE) {
    if (!control) {
      return undefined; // invalid configuration, cannot instantiate date input
    }
    (formFieldData as unknown as DateFormFieldData).control = control;
    (formFieldData as unknown as DateFormFieldData).validate = get(fieldDefinitions, [name, 'validate']);
  }

  if (name === dobFieldName) {
    return {
      ...(omit(formFieldData, 'ref') as FormFieldData),
      className: 'padding-top-3',
      fieldType: FieldType.READ_ONLY,
      name: label,
      value: currentValue,
    };
  }

  if (name === countryOfIssuanceFieldName) {
    return {
      ...omit(formFieldData, 'ref'),
      fieldType: FieldType.CUSTOM,
      render: ({ errorMessage, ...props }): React.JSX.Element => (
        /* eslint-disable react/jsx-props-no-spreading */
        <CountrySelect
          {...props}
          error={errorMessage}
          register={register}
          setValue={(valueName: any, value: any): void => setValue(valueName, value)}
        />
      ),
    };
  }

  return formFieldData as FormFieldData;
};

/* eslint-disable import/prefer-default-export */
export const mapReverifySectionFieldDataToFormFieldData = (
  data: ReverifySectionFieldData[] = [],
  reactHookFormProps: ReactHookFormProps = {
    control: undefined,
    errors: {},
    register: registerNoop,
    setValue: noop,
    watch: () => undefined,
  },
): FormFieldData[] =>
  data
    .map((fieldData) => getFormFieldDataForReverifySectionFieldData(fieldData, reactHookFormProps))
    .filter((formFieldData: FormFieldData | undefined) => formFieldData) as FormFieldData[];
