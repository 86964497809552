import React, { useState } from 'react';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import reduce from 'lodash/reduce';
import { Form } from '@ver-uds/uswds-react';
import { SubmitHandler, useForm } from 'react-hook-form';
import noop from 'lodash/noop';
import { Helmet } from 'react-helmet-async';
import { CustomClassValue } from '../../../../utils/types';
import { UserInfoResponseData } from '../../../../services/UserApi';
import { formatDate } from '../../../../utils/dates';
import { maskSsn } from '../../../../utils/formatting';
import FormFields, { FieldType, FormFieldData } from '../../../Form/FormFields/FormFieldsModernized';
import {
  middleNameFieldName,
  UserInfoFormData,
  firstNameFieldName,
  lastNameFieldName,
  fieldDefinitions,
  UserInfoFieldName,
  selfCheckPageTitles,
} from '../constants';
import ActionFooter from '../../../ActionFooter/ActionFooter';
import { getFieldError } from '../../../../utils/forms';

export const RENDERED_USER_FORM_FIELDS_IN_ORDER: string[] = ['firstName', 'middleName', 'lastName', 'dob', 'ssn'];

interface SelfCheckFormConfirmIdentityMarkupUserFieldsBaseProps {
  className?: CustomClassValue;
  hideSsn?: boolean;
  userInfo?: UserInfoFormData;
  onSubmit?: SubmitHandler<UserInfoFormData>;
  isEditable?: boolean;
  identityFormData?: UserInfoFormData;
}

export type SelfCheckFormConfirmIdentityMarkupUserFieldsProps = SelfCheckFormConfirmIdentityMarkupUserFieldsBaseProps &
  (
    | {
        onSubmit: SubmitHandler<UserInfoFormData>;
        isEditable: true;
      }
    | {
        onSubmit?: undefined;
        isEditable?: false;
      }
  );

const baseClassName = 'self-check-confirm-identity-user-field-group';
const CLASS_NAMES = {
  base: classNames(baseClassName, 'grid-row', 'grid-gap'),
  field: classNames(`${baseClassName}__field`, 'grid-col-12'),
};

function getFieldClassName(key: keyof UserInfoResponseData): CustomClassValue {
  return classNames(CLASS_NAMES.field, {
    'maxw-card-lg': key === 'firstName' || key === 'lastName' || key === 'middleName',
    'padding-top-2': key === 'dob',
  });
}

function parseFieldVal(
  key: keyof UserInfoResponseData,
  val?: string | boolean | undefined,
): string | boolean | undefined {
  if (key === 'dob' && typeof val === 'string') {
    return formatDate(val);
  }

  if (key === 'ssn' && typeof val === 'string') {
    return maskSsn(val);
  }

  if (key === 'middleName' && typeof val === 'string' && !isEmpty(val)) {
    return val.charAt(0);
  }

  return val;
}

type UserInfoEditableFieldName = 'firstName' | 'lastName' | 'middleName';

function SelfCheckFormConfirmIdentityMarkupUserFields({
  className = undefined,
  hideSsn = undefined,
  userInfo = undefined,
  onSubmit = noop,
  isEditable = undefined,
  identityFormData = undefined,
}: SelfCheckFormConfirmIdentityMarkupUserFieldsProps): React.JSX.Element {
  const [isEditing, setEditing] = useState(false);

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Pick<UserInfoFormData, 'firstName' | 'lastName' | 'middleName'>>({
    mode: 'all',
    defaultValues: {
      firstName: identityFormData?.[firstNameFieldName] || userInfo?.[firstNameFieldName],
      middleName: identityFormData?.[middleNameFieldName] || userInfo?.[middleNameFieldName],
      lastName: identityFormData?.[lastNameFieldName] || userInfo?.[lastNameFieldName],
    },
  });

  if (!userInfo) {
    return <div />;
  }

  const handleFormSubmit = handleSubmit((formData) => {
    onSubmit({ ...userInfo, ...identityFormData, ...formData });
  });

  const toggleIsEditing = (): void => {
    reset();
    setEditing(!isEditing);
  };

  const data = reduce(
    {
      ...userInfo,
      ...identityFormData,
      middleName: userInfo.middleName || undefined, // explicitly define middleName in order to ensure middleName field renders
    },
    (accumulator: FormFieldData[], val, key) => {
      if (!RENDERED_USER_FORM_FIELDS_IN_ORDER.includes(key) || (hideSsn && key === 'ssn')) {
        return accumulator;
      }
      const fieldClassName = getFieldClassName(key as keyof UserInfoResponseData);
      const { label, validation, errorMessages } = fieldDefinitions[key as UserInfoFieldName];

      // field type based on edit state
      if (!isEditing || key === 'dob' || key === 'ssn') {
        accumulator.push({
          key,
          className: fieldClassName,
          name: label,
          value: parseFieldVal(key as keyof UserInfoResponseData, val),
          fieldType: FieldType.READ_ONLY,
        });
      } else {
        const isRequired = key !== 'middleName';
        const registeredField = register(key as UserInfoEditableFieldName, validation as any);

        accumulator.push({
          key,
          className: fieldClassName,
          // name: key,
          fieldType: FieldType.TEXT,
          required: isRequired,
          id: key,
          label,
          // ref: register(key as UserInfoEditableFieldName, validation as any).ref,
          ...registeredField,
          errorMessage: getFieldError(errors[key as UserInfoEditableFieldName], errorMessages),
          defaultValue: parseFieldVal(key as keyof UserInfoResponseData, val) as string,
        });
      }
      return accumulator;
    },
    [],
  );

  return (
    <>
      <Helmet>
        <title>
          {isEditing ? selfCheckPageTitles.EDIT_PERSONAL_INFORMATION : selfCheckPageTitles.CONFIRM_IDENTITY_INFORMATION}
        </title>
      </Helmet>
      <Form className={classNames(CLASS_NAMES.base, classNames, className)} onSubmit={handleFormSubmit}>
        <div aria-live="polite">
          <FormFields data={data} sortOrder={RENDERED_USER_FORM_FIELDS_IN_ORDER} />
        </div>
      </Form>
      {!isEditable && <p>This form is not editiable</p>}
      {isEditable && (
        <ActionFooter
          cancelButtonText={isEditing ? 'Cancel Edit' : 'Edit Personal Information'}
          onCancel={toggleIsEditing}
          // cancelTempDisabled
          hideCancelButton={false}
          onSubmit={handleFormSubmit}
          submitButtonText="Next"
        />
      )}
    </>
  );
}

export default SelfCheckFormConfirmIdentityMarkupUserFields;
