import React from 'react';
import classNames from 'classnames';
import styled from 'styled-components';
import { FieldGroup } from '@ver-uds/react';
import { CustomClassValue } from '../../../../utils/types';
import FormFields, { FieldType, FormFieldsProps, ReadOnlyFieldData } from '../FormFieldsModernized';

const StyledFieldGroup = styled(FieldGroup)`
  > .ver-field-group__field:last-child {
    margin-bottom: 1rem;
  }
`;

export type FieldProps = Omit<ReadOnlyFieldData, 'fieldType'>;

export interface ReadOnlyFieldsProps extends Omit<FormFieldsProps, 'data'> {
  className?: CustomClassValue;
  data?: FieldProps[];
}

const baseClassName = 'read-only-fields';
const CLASS_NAMES = {
  base: baseClassName,
  field: `${baseClassName}__field`,
};

function ReadOnlyFields({
  className = undefined,
  data = undefined,
  sortOrder,
}: ReadOnlyFieldsProps): React.JSX.Element {
  return (
    <StyledFieldGroup className={classNames(CLASS_NAMES.base, className)}>
      <FormFields
        data={data?.map((item) => ({
          ...item,
          fieldType: FieldType.READ_ONLY,
        }))}
        sortOrder={sortOrder}
      />
    </StyledFieldGroup>
  );
}
export default ReadOnlyFields;
