import React from 'react';
import { Control, Controller } from 'react-hook-form';
import classNames from 'classnames';
// import get from 'lodash/get';
// import isEmpty from 'lodash/isEmpty';
// import isUndefined from 'lodash/isUndefined';
import omit from 'lodash/omit';
import { FieldGroup } from '@ver-uds/react';
import { DropdownControl, TextInputControl } from '@ver-uds/uswds-react';
import { EMPTY_VAL_CHAR } from '../../../constants';
import { sortByKeyToMatchList } from '../../../utils/collections';
import { ValidateRules } from '../../../utils/forms';
import { CustomClassValue } from '../../../utils/types';
import FormattedDateInput from '../FormattedDateInput/FormattedDateInput';

const CLASS_NAMES = {
  base: 'form-fields',
  field: 'form-fields__field',
};

// TODO: figure out why ESLint thinks this is being defined multiple times
/* eslint-disable-next-line no-shadow */
export enum FieldType {
  DATE = 'DATE',
  READ_ONLY = 'READ_ONLY',
  SELECT = 'SELECT',
  TEXT = 'TEXT',
  CUSTOM = 'CUSTOM',
}

export interface FieldData {
  className?: CustomClassValue;
  fieldType: FieldType;
  key?: string;
  label?: string;
  name: string;
  onBlur?: any;
  onChange?: any;
  ref?: any;
}

export interface ReadOnlyFieldData extends FieldData {
  value?: string | boolean;
  fieldType: FieldType.READ_ONLY;
}

export interface BaseFormFieldData extends FieldData {
  id: string;
  defaultValue?: string;
  disabled?: boolean;
  errorMessage?: string;
  hint?: string;
  label: string;
  labelHint?: string;
  onBlur?: any;
  onChange?: any;
  required?: boolean;
  value?: string;
  ref?: any;
}

export interface TextFormFieldData extends BaseFormFieldData {
  defaultValue?: string;
  fieldType: FieldType.TEXT;
  value?: string;
}

export interface SelectFormFieldData extends BaseFormFieldData {
  fieldType: FieldType.SELECT;
  options: { label: string; value: string }[];
}

export interface DateFormFieldData extends BaseFormFieldData {
  control: Control;
  fieldType: FieldType.DATE;
  validate?: ValidateRules;
}

export interface CustomFormFieldData extends BaseFormFieldData {
  fieldType: FieldType.CUSTOM;
  render: (props: CustomFormFieldData) => React.JSX.Element;
}

export type FormFieldData =
  | ReadOnlyFieldData
  | TextFormFieldData
  | SelectFormFieldData
  | DateFormFieldData
  | CustomFormFieldData;

export interface FormFieldsProps {
  data?: FormFieldData[];
  sortOrder?: string[];
}

const getSortedFields = (data: FieldData[] = [], sortOrder: string[] = []): FieldData[] => {
  const key = data[0]?.key ? 'key' : 'name';
  return sortByKeyToMatchList(data, sortOrder, key) as FieldData[];
};

const renderField = (formField: FormFieldData) => {
  const { key, name, fieldType, ...rest } = formField;

  switch (fieldType) {
    case FieldType.READ_ONLY:
      return (
        <FieldGroup.Field key={key || name} name={name}>
          {(rest as ReadOnlyFieldData).value || EMPTY_VAL_CHAR}
        </FieldGroup.Field>
      );
    case FieldType.TEXT:
      return (
        <TextInputControl
          id={formField.id}
          // defaultValue={formField.value || formField.defaultValue}
          disabled={formField.disabled}
          errorId={formField.errorMessage ? `${formField.id}--error` : undefined}
          errorMessage={formField.errorMessage}
          label={formField.label}
          labelHint={formField.labelHint}
          name={formField.name}
          onBlur={formField.onBlur}
          onChange={formField.onChange}
          required={formField.required}
          ref={formField.ref}
        />
      );
    case FieldType.SELECT:
      return (
        <DropdownControl
          id={formField.id}
          // defaultValue={formField.defaultValue}
          disabled={formField.disabled}
          errorId={formField.errorMessage ? `${formField.id}--error` : undefined}
          errorMessage={formField.errorMessage}
          label={formField.label}
          labelHint={formField.labelHint}
          required={formField.required}
          ref={formField.ref}
        >
          <option value="">- Select -</option>
          {(rest as SelectFormFieldData).options.map(({ label, value }) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </DropdownControl>
      );
    case FieldType.DATE:
      return (
        <Controller
          control={formField.control}
          name={name}
          rules={{
            required: formField.required,
            validate: formField.validate,
          }}
          render={({ field }) => (
            <FormattedDateInput
              {...(rest as DateFormFieldData)}
              formattedDate={field.value}
              onUpdate={field.onChange}
            />
          )}
        />
      );
    case FieldType.CUSTOM:
      return (rest as CustomFormFieldData).render(omit(rest, 'render') as CustomFormFieldData);
    default:
      return null;
  }
};

function FormFields({ data = [], sortOrder = [] }: FormFieldsProps): React.JSX.Element {
  const fields = getSortedFields(data, sortOrder);

  return (
    <>
      {fields.map((field) => (
        <div key={field.key || field.name} className={classNames(CLASS_NAMES.field, field.className)}>
          {renderField(field as FormFieldData)}
        </div>
      ))}
    </>
  );
}

export default FormFields;
