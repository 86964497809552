import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import paths from '../../../../routing/paths';
import {
  SelfCheckCaseResponseData,
  SubmitSelfCheckCaseRequest,
  useSubmitSelfCheckCaseMutation,
} from '../../../../services/SelfCheckApi';
import { injectURLParams } from '../../../../utils/routing';
import SelfCheckFormReviewMarkup, {
  REVERIFY_CASE_STATUS,
  SelfCheckFormReviewMarkupProps,
} from './SelfCheckFormReviewMarkup';
import { SelfCheckFormData } from '../constants';

export interface SelfCheckFormReviewProps
  extends Omit<SelfCheckFormReviewMarkupProps, 'onSubmit' | 'onReverifySuccess'> {
  onCaseSubmitSuccess: (caseData?: SelfCheckCaseResponseData, updatedFormData?: SelfCheckFormData) => void;
}

function SelfCheckFormReview({ onCaseSubmitSuccess, data, ...props }: SelfCheckFormReviewProps): React.JSX.Element {
  const {
    mutate,
    data: submitCaseResponseData,
    error: submissionError,
    isPending: isSubmissionLoading,
    isSuccess: isSubmissionSuccess,
  } = useSubmitSelfCheckCaseMutation();
  // const [formData, setFormData] = React.useState(data);
  const navigate = useNavigate();

  useEffect(() => {
    // useEffect is required as long as onCaseSubmitSuccess causes a state transition which results in a re-render in this (child) component
    if (isSubmissionSuccess) {
      if (submitCaseResponseData?.caseStatus !== REVERIFY_CASE_STATUS) {
        // Navigate is important here to ensure the page transitions during the Review step
        navigate(injectURLParams(paths.caseDetail, { caseNumber: submitCaseResponseData?.caseNumber }));
        onCaseSubmitSuccess(undefined, data);
      } else {
        onCaseSubmitSuccess(submitCaseResponseData);
      }
    }
  }, [data, isSubmissionSuccess, navigate, onCaseSubmitSuccess, submitCaseResponseData]);

  if (submissionError) {
    /**
     * TODO: https://maestro.dhs.gov/jira/browse/UVME-2892
     * show modal alert and remain on page
     */
    console.log(`submission error:\n${JSON.stringify(submissionError)}`); // eslint-disable-line no-console
  }

  const handleSubmit = (payload: SubmitSelfCheckCaseRequest): void => mutate(payload);

  return (
    <div>
      {!isSubmissionLoading && (
        <SelfCheckFormReviewMarkup
          {...props} // eslint-disable-line react/jsx-props-no-spreading
          data={data}
          onReverifySuccess={onCaseSubmitSuccess}
          onSubmit={handleSubmit}
        />
      )}
    </div>
  );
}

export default SelfCheckFormReview;
