import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
// import isUndefined from 'lodash/isUndefined';
import paths from '../../routing/paths';
import { useCountriesQuery } from '../../services/ReferenceApi';
import { SetValueFn } from '../../utils/forms';
import { DEFAULT_NAME } from './constants';
import CountrySelectMarkup, { CountrySelectMarkupProps } from './CountrySelectMarkup';

// defaultValue and setValue must be provided together
export type CountrySelectProps = Omit<CountrySelectMarkupProps, 'options'> & {
  setValue: SetValueFn<Partial<{ [name: string]: string }>>;
};

function CountrySelect({ defaultValue, name, register, setValue, ...props }: CountrySelectProps): React.JSX.Element {
  const { error, data } = useCountriesQuery();
  const [hasSetDefaultValue, setHasSetDefaultValue] = useState<boolean>(false);

  useEffect(() => {
    if (!isEmpty(data) && !hasSetDefaultValue) {
      if (!isEmpty(defaultValue) && typeof setValue === 'function') {
        // if (defaultValue && setValue) {
        setValue(name || DEFAULT_NAME, defaultValue);
        setHasSetDefaultValue(true);
      } else {
        setHasSetDefaultValue(false);
      }
    }
    // if (!isEmpty(data) && typeof setValue === 'function') {
    //   setValue(name || DEFAULT_NAME, defaultValue || '');
    //   setHasSetDefaultValue(true);
    // }
  }, [data, defaultValue, hasSetDefaultValue, name, setValue]);

  if (error) {
    return <Navigate to={paths.error} />;
  }

  return (
    <CountrySelectMarkup
      {...props} // eslint-disable-line react/jsx-props-no-spreading
      name={name}
      options={data || []}
      defaultValue={defaultValue}
      register={register}
    />
  );
}

export default CountrySelect;
